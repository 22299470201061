

.sparkle{
    background-image: url("../../assets/images/sparkle.png");
    background-position: 95% 0 ;
    background-repeat: no-repeat;
    background-size:98px 76px;
}
.oval{
    background-image: url("../../assets/images/oval.png");
    background-position: center;
    background-repeat: no-repeat;
}
.sparkle-double{
    background-image: url("../../assets/images/sparkle-left.png"), url("../../assets/images/sparkle-right.png");
    background-position: left 0,bottom right;
    background-repeat: no-repeat;
    background-size:56px 76px;
}
@media (max-width: 768px) {
    .sparkle{
        background-position: 98% 0 ;
        
    }

}
@media (min-width: 768px) {
    .bicycle{
        background-image: url("../../assets/images/bicycle.png");
        background-position: center right ;
        background-repeat: no-repeat;
    } 
}