@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: "FcMinimal-bold";
    src: url("./assets/font/FC\ Minimal\ Bold.otf");
}
@font-face {
    font-family: "FcMinimal-normal";
    src: url("./assets/font/FC\ Minimal\ Regular.otf");
}
@font-face {
    font-family: "CodeBold";
    src: url("./assets/font/CODE\ Bold.otf");
}
@font-face {
    font-family: "FcPalette";
    src: url("./assets/font/FC\ Palette.otf");
}
body{
    margin:0;
    width: 100%;
    height: 100vh;
    background-image: url("./assets/images/Grid.png");
    background-color: #1454CC;;
    background-repeat:repeat;
    background-size:100%
}


